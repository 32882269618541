<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Tải lên file">
        <b-form @submit.prevent="handleSubmit()">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="File ảnh"
                label-cols-md="2"
                label-for="h-images"
              >
                <b-form-file
                  id="h-image"
                  v-model="files"
                  drop-placeholder="Thả file tại đây..."
                  multiple
                  placeholder="Chọn 1 hoặc nhiều file..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="File"
                label-cols-md="2"
                label-for="h-images"
              >
                <b-form-textarea
                  id="post-summary"
                  v-model="uploadedPaths"
                  rows="20"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-col offset-md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              @click="reset()"
            >
              Xóa
            </b-button>
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              type="submit"
              variant="primary"
            >
              Tải lên
            </b-button>
          </b-col>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BRow, BCard, BForm, BFormGroup, BFormFile, BFormTextarea, BButton} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";

export default {
  name: "HrdcFileUpload",
  components: {BRow, BCol, BCard, BForm, BFormGroup, BFormFile, BFormTextarea, BButton},
  directives: {
    Ripple
  },
  data() {
    return {
      files: [],
      response: [],
      loading: false
    }
  },
  computed: {
    uploadedPaths () {
      return this.response.join("\n")
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      this.response = []
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        const formData = new FormData();
        formData.append('image', file);
        const res = await this.$http.post("/upload/image", formData);
        if (res.status === 200) {
          this.response.push(`https://hrdc.devculi.com/${res.data}`)
        } else {
          this.response.push(`ERROR: ${file.path}`)
        }
      }
      this.loading = false
    },
    reset () {
      this.loading = false;
      this.response = []
      this.files = []
    }
  }
}
</script>

<style>
</style>
